import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  var _component_router_view = _resolveComponent("router-view");

  var _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createBlock(_component_el_container, {
    class: "fill-container flex-col",
    style: {
      "justify-content": "center"
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_router_view)];
    }),
    _: 1
  });
}